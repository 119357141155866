
#login-logo {
  max-width: 400px;
}

#dashboardContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    --padding-start: 2%;
    --padding-end:2%;
}

  ion-card{
    display: flex;
    object-fit: contain;
    flex-direction: column;
  }
ion-col{
  max-height: 98%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
ion-row {
  height: 40%;
}
ion-header {
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
}  

ion-footer {
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
}  

ion-content {
   max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    fill: red;
  }

  #dashCardImg {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }

  .dashboardCard {   
    display: inline-block;
    padding: 4%;
    margin: 3%;
    /*min-width: 100px;*/
    width: 44%;
    /*max-width: 150px;  
    min-height: 100px;  
    max-height: 150px;*/
    text-align: center;
    
	align-content: center;
}

.icon {
  fill: #488aff;
}

.iconBlue {
  fill: red;
  stroke: blue;
}

.iconLarge {
  font-size: 128px;
}
