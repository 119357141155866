
 .rpe {
    display: inline;
}
#up2 {
    background-color: pink;
  }

  #col_0 {
    background-color: #FF1B00;
    display: inline;
}
#col_1 {
    background-color: #FF1B00;
    display: inline;
}
#col_2 {
    background-color: #FF4C00;
    display: inline;
}
#col_3 {
    background-color: #FF7D01;
    display: inline;
}
#col_4 {
    background-color: #FFAD01;
    display: inline;
}
#col_5 {
    background-color: #FFDD02;
    display: inline;
}
#col_6 {
    background-color: #F1FF02;
    display: inline;
}
#col_7 {
    background-color: #C1FF03;
    display: inline;
}
#col_8 {
    background-color: #92FF03;
    display: inline;
}
#col_9 {
    background-color: #62FF04;
    display: inline;
}
#col_10 {
    background-color: #33FF05;
    display: inline;
}
#col_11 {
    background-color: #33FF05;
    display: inline;
}
 