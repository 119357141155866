#up2 {
    background-color: pink;
  }

  #col_0 {
    background-color: #FF1B00;
}
#col_1 {
    background-color: #FF1B00;
}
#col_2 {
    background-color: #FF4C00;
}
#col_3 {
    background-color: #FF7D01;
}
#col_4 {
    background-color: #FFAD01;
}
#col_5 {
    background-color: #FFDD02;
}
#col_6 {
    background-color: #F1FF02;
}
#col_7 {
    background-color: #C1FF03;
}
#col_8 {
    background-color: #92FF03;
}
#col_9 {
    background-color: #62FF04;
}
#col_10 {
    background-color: #33FF05;
}
#col_11 {
    background-color: #33FF05;
}
#narrowImg {
     width: 30%;
 }
ion-icon {
     width: 20px;
     height: 20px
 }
 ion-badge {
     margin: 1px;
     --padding-top: 0px;
 }